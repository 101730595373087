import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="f5375cac4c549606c3af1ad159a06c9baa51fb7e"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="parent-active first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/comment-agit-imodium/"> Comment agit IMODIUM®? </a>
              </li>
              <li className="active last">
                <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                  La substance active : lopéramide
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
				<a href="/fr/medicaments-imodium/">
                <h4>Quel IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Range_packshot_NL_small.JPG"
                    width="170"
                    height="102"
                    alt="Quel IMODIUM®?"
                  />
                </div>
                <p>
                  Cherchez quel <strong>IMODIUM®</strong> est le plus adapté
                  pour vous.&nbsp;
                </p>
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Comment agit IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Comment agit IMODIUM®?"
                  />
                </div>
                <p>
                  Cette vidéo montre comment Imodium<sup>®</sup>&nbsp;aide à
                  restaurer l‘équilibre naturel de vos intestins.
                </p>
                <a href="/fr/comment-agit-imodium/">Plus d'info</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-11">
            <h1>La substance active : lopéramide</h1>
            <div className="intro row">
              <div className="three columns">
                <h2>Substance active</h2>
              </div>
              <div className="nine columns">
                <p>
                  Le <strong>lopéramide</strong> est disponible en Belgique
                  depuis 1974 sous le nom <strong>IMODIUM®</strong> et il est
                  utilisé pour le traitement des symptômes de la diarrhée.
                </p>
              </div>
            </div>
            <h3>Que se passe-t-il dans l’intestin en cas de diarrhée ?</h3>
            <p>
              La plupart des gens connaissent les
              <strong> symptômes typiques de la diarrhée</strong> : des selles
              trop fréquentes et trop liquides.&nbsp;Que se passe-t-il dans
              votre intestin en cas de diarrhée?
            </p>
            <p>
              <strong>En cas de diarrhée</strong>, nous constatons toujours
              trois changements dans la fonction intestinale&nbsp;:
            </p>
            <ul>
              <li>
                <strong>Une motilité accrue&nbsp;:</strong>
                <br />
                l’intestin fonctionne plus vite que la normale, ce qui fait que
                son contenu est évacué plus rapidement.
                <br />
                &nbsp;
              </li>
              <li>
                <strong>Moindre résorption des liquides de l’intestin :</strong>
                <br />
                en cas de digestion normale, les liquides, les électrolytes et
                les nutriments contenus dans les aliments sont absorbés dans
                l’intestin. Mais en raison de la motilité accrue, le corps
                dispose de moins de temps pour cela. C’est pourquoi les selles
                restent liquides. Étant donné que le corps reçoit désormais
                moins de liquides et de nutriments, il existe un risque de
                déshydratation.
                <br />
                &nbsp;
              </li>
              <li>
                <strong>Sécrétion accrue : </strong>
                <br />
                par ailleurs, le corps libère davantage de liquides et
                d’électrolytes dans l’intestin. Ceci rend les selles de plus en
                plus liquides et de plus en plus impérieuses.
              </li>
            </ul>
            <p>&nbsp;</p>
            <h3>
              Le lopéramide agit contre ces troubles de la fonction intestinale
            </h3>
            <p>
              La substance active, le lopéramide, agit contre les trois troubles
              de la fonction intestinale cités plus haut, ce qui rend les selles
              plus consistantes et prévient la déshydratation.
              <br />
              Le lopéramide réduit l'activité intestinale excessive. Les aliments restent plus longtemps dans l’intestin,
              et le corps peut ainsi absorber davantage de liquides et de
              nutriments. En outre, la sécrétion accrue de liquides et
              d’électrolytes dans l’intestin est à nouveau normalisée.
            </p>
            <p>&nbsp;</p>
            <h3>Propriétés du lopéramide</h3>
            <ul>
              <li>
                <strong>Le lopéramide</strong> agit contre la déshydratation{" "}
                <strong>en cas de diarrhée</strong>. L’absorption de liquides et
                d’électrolytes est améliorée.
              </li>
              <li>
                La durée de la diarrhée peut être significativement réduite avec
                le loperamide.
              </li>
              <li>
                <strong>Le lopéramide</strong> aide le corps à èpaissir les
                selles en normalisant la sécrétion élevée de liquides et d'électrolytes. Cet équilibre
                naturel va diminuer les chances que des microbes responsables de
                la maladie agissent sur l'intestin.
              </li>
              <li>
                <strong>Le lopéramide</strong> contracte le muscle de l'anus. De
                cette manière, il{" "}
                <strong>empêche l'évacuation de selles</strong> et
                vous épargne des situations gênantes.
              </li>
            </ul>
            <p>&nbsp;</p>
            <h3>La combinaison de loperamide et simeticon</h3>
            <p>
              En plus du lopéramide (2 mg), IMODIUM® Duo contient aussi le
              principe actif siméticone (125 mg) afin d’alléger les problèmes de
              formation de gaz dans la cavité abdominale. La siméticone veille à
              la dissolution dans l’intestin grêle des bulles de gaz, qui
              provoquent des crampes et une sensation de ballonnement.IMODIUM®
              Duo traite efficacement la diarrhée aiguë&nbsp; acompagnée de
              désagréments tels que flatulences, crampes ou ballonnements.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
